import logo1 from '../assets/images/logo-1.png';
import logo2 from '../assets/images/logo-2.png';
import logo3 from '../assets/images/logo-3.png';
import logo4 from '../assets/images/logo-4.png';
import logo5 from '../assets/images/logo-5.png';
import heroBanner from '../assets/images/hero-banner.jpg';
import ButtonCheckout from './ButtonCheckout';
import ButtonGetInTouch from './ButtonGetInTouch';

const BannerHomepage = () =>{
    return (
        <>
            <section id="hero-banner" className="hero-banner section">
                <div className="wrapper">
                    <div className="hero-content">
                        <h1 className="gradient-text">Take charge of your <span>Online Security</span></h1>
                        <p className="big">Comprehensive Cyber Security Solutions for Businesses and Consumers</p>
                        <div className="btn-flex-holder">
                            <ButtonCheckout />
                            <ButtonGetInTouch />
                        </div>
                        <div className="in-partnership">
                            <div className="block-title">API Partnerships</div>
                            <div className="logos flex-wrap flex-align-center">
                                <img loading="lazy" src={ logo1 } className="img-as-block" alt="" />
                                <img loading="lazy" src={ logo2 } className="img-as-block" alt="" />
                                <img loading="lazy" src={ logo3 } className="img-as-block" alt="" />
                                <img loading="lazy" src={ logo4 } className="img-as-block" alt="" />
                                <img loading="lazy" src={ logo5 } className="img-as-block" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="hero-image">
                        <img loading="lazy" src={ heroBanner } alt="" className="img-as-block" />
                    </div>
                    
                </div>
            </section>
        </>
    );
}
export default BannerHomepage