import Slider from "react-slick";
import logoOrchestrate from "../assets/images/logo-orchestrate.png"

const SliderCustomers = () =>{
    var settings = {
        dots: true,
        auto: false,
        infinite: false,
        speed: 300,
        arrows:false,
        slidesToShow: 1,
        variableWidth: true,
        adaptiveHeight: true,
        responsive: [
            {
            breakpoint: 1120,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
            }
            }
        ]
    };
    return (
        <>
        <section className="has-border-top flexbox section slider-block">
            <div className="wrapper">
                <div className="flex-wrap col-gap-30 content-right">
                    <div className="one-half">
                        <div className="content-block width-2">
                            <div className="intro">Customers</div>
                            <h2>We have changed the game of Cyber Security—hear it from our customers.</h2>
                        </div>
                    </div>
                    <div className="one-half">
                        <div className="offset-content-1">
                        <p>At the core of our mission is the belief that everyone deserves peace of mind when navigating the online realm. We strive to empower our clients with the tools and information they need to defend against cyber-attacks, data breaches, and identity theft. By offering a robust suite of Cyber Security services, we aim to make the internet a safer place for individuals and businesses alike.</p>
                        </div>
                    </div>
                </div>
                <div className="slider-area-wrap">
                    <div className="slideshow-master">
                    <Slider {...settings}>
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="image">
                                        <img loading="lazy" src={ logoOrchestrate } alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="med">"Ever since I started using CyberAlerts, my peace of mind has skyrocketed! As a small business owner, I understand the significance of Cyber Security, but I was always concerned about how to effectively protect my business from potential threats. Their Dark Web monitoring service is a revelation. I had no idea that my employees' email addresses and passwords were circulating on the dark web. With CyberAlerts, I now receive immediate alerts whenever there's a potential data leak, allowing me to take prompt action and secure our accounts. It's like having a constant watchful eye on all our digital credentials.”</p>
                                        <div className="author">
                                            <div className="author-name">Jeff Wake</div>
                                            <div className="author-position">CEO, Orchestrate Worldwide</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">2</div>
                        </div>
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">3</div>
                        </div>
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">4</div>
                        </div>
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">5</div>
                        </div>
                    </Slider>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default SliderCustomers