import { useEffect, useState } from "react";
import logo from '../assets/images/logo-black.png'
import herobanner from "../assets/images/hero-banner-v2-r2-image.png"
import iconAI from "../assets/images/icon-ai.png"
import bannerlogo1 from "../assets/images/logo-v2-r2-1.png"
import bannerlogo2 from "../assets/images/logo-v2-r2-2.png"
import bannerlogo3 from "../assets/images/logo-v2-r2-3.png"
import bannerlogo4 from "../assets/images/logo-v2-r2-4.png"
import bannerlogo5 from "../assets/images/logo-v2-r2-5.png"
import warningIcon from "../assets/images/warning-icon.png"
import audio1 from "../assets/images/audio-1.png"
import imageWithText1 from "../assets/images/image-with-text-3.jpg"
import preventIcon1 from "../assets/images/prevent-icon-v2-1.png"
import preventIcon2 from "../assets/images/prevent-icon-v2-2.png"
import preventIcon3 from "../assets/images/prevent-icon-v2-3.png"
import gridIcon1 from "../assets/images/grid-icon-1.png"
import gridIcon2 from "../assets/images/grid-icon-2.png"
import gridIcon5 from "../assets/images/grid-icon-5.png"
import gridIcon6 from "../assets/images/grid-icon-6.png"

import SecureImage1 from '../assets/images/safe-secure-1.png';
import SecureImage2 from '../assets/images/safe-secure-2.png';
import SecureImage3 from '../assets/images/safe-secure-3.png';
import SecureImage4 from '../assets/images/safe-secure-payments.png';
import SliderCustomersV2 from "../sections/SliderCustomerV2";
import CustomBanner from '../assets/images/custom-banner-mobile-image.jpg';
import Footer from "../sections/Footer";

const LandingPageV2R2 = ({ classes }) =>{

    function getOperatingSystem(window) {
        let operatingSystem = 'Not known';
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
        if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
        if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
        if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

        return operatingSystem;
    }


    const [userLocation, setUserLocation]  = useState("");

    const [ip, setIP] = useState("");

    const getData = async () => {
        fetch('https://api.ipify.org?format=json').then(response => {
            return response.json();
        }).then((res) => {
            setIP(res.ip);
            fetch('https://api.iplocation.net/?ip=' + res.ip ).then(response => {
                return response.json();
            }).then((res) => {
                console.log(res);
                setUserLocation(res.country_name)
            }).catch((err) => console.error('Problem fetching my IP', err))

        }).catch((err) => console.error('Problem fetching my IP', err))

    };

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
        getData();
    }, [] );

    return (
        <>
            <div className="landing-page-v2 v2-r2">
                <div id="page-container">

                    <header id="header">
                        <div className="wrapper flex-wrap flex-justify-between flex-align-center">
                            <div className="header-logo flex-wrap flex-align-center">
                                <a href="/" className="logo"><img loading="lazy" src={ logo } alt="" className="img-as-block" /></a>
                                <div className="site-slogan">Start Your 24/7 Complete Protection Trial Today, For Only $1!</div>
                            </div>
                            <div className="btn-flex-holder">
                                <a href="/checkout" className="btn blue-btn">
                                    <span>Have I Been Hacked</span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </header>

                    <section id="hero-banner" className="hero-banner section radial-bg loaded">
                        <div className="wrapper">
                            <div className="hero-content">
                                <div className="custom-intro"><span><img src={ iconAI } alt="" className="img-as-block" /></span>VOICE SCAMS ARE ON THE RISE</div>
                                <h1 className="gradient-text"><span>Have You Been Hacked?</span></h1>
                                <p className="big">Protecting your identity has never been more important. Online criminals  continue to evolve, safeguarding yourself and your family is imperative.</p>
                                <div className="btn-flex-holder">
                                    <a href="checkout-step-1.html" className="btn blue-btn">
                                        <span>Have I Been Hacked? Click Here to Find Out</span>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                        </svg>
                                    </a>
                                </div>
                                <div className="protected-by">
                                    <h5 className="label">Protected by:</h5>
                                    <div className="logos flex-wrap flex-align-center">
                                        <img loading="lazy" src={ bannerlogo1 } className="img-as-block" alt="" />
                                        <img loading="lazy" src={ bannerlogo2 } className="img-as-block" alt="" />
                                        <img loading="lazy" src={ bannerlogo3 } className="img-as-block" alt="" />
                                        <img loading="lazy" src={ bannerlogo4 } className="img-as-block" alt="" />
                                        <img loading="lazy" src={ bannerlogo5 } className="img-as-block" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="hero-image">
                                <img loading="lazy" src={ herobanner } alt="" className="img-as-block" />
                            </div>
                        </div>
                    </section>

                    <section id="v2-custom-section-1" className="section flexbox">
                        <div className="wrapper">
                            <div className="medium-page-content-block">
                                <div className="page-content-block text-center">
                                    <div className="intro">Even the most careful people are at risk</div>
                                    <h2 className="big gradient-text">Your Information Is <span>Already Out There</span></h2>
                                    <div className="ip-block text-black">
                                        <div>Your IP Address is <span className="">{ip}</span></div>
                                        
                                        { userLocation ? (
                                            <div>You are at <span className=""> { userLocation }
                                            </span></div>
                                        ) : (<></>)}
                                        <div>You use a  <span className="">{ getOperatingSystem(window) }</span></div>
                                    </div>
                                    <p className="desc">And this just scratches the surface of what is known about you. Bad actors can have your identity, your family members’ identity, phone numbers, email info, and even passwords.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="warning-section section flexbox radial7-bg loaded">
                        <div className="wrapper">
                            <div className="custom-block-style-4">
                                <div className="styled-content">
                                    <img src={ warningIcon } alt="" className="warning-icon" />
                                    <h3 className="intro text-white text-center text-uppercase">Even the most careful people are at risk</h3>
                                    <h2 className="big gradient-text text-center"><span>Can You Tell The Difference?</span></h2>
                                    <div className="flex-wrap flex-wrap-block col-gap-30 audio-grid flex-align-center flex-justify-center">
                                        <div className="grid-item">
                                            <img src={ audio1} alt="" className="img-as-block" />
                                        </div>
                                        <div className="grid-item">
                                            <img src={ audio1} alt="" className="img-as-block" />
                                        </div>
                                    </div>
                                            
                                    <div className="btn-flex-holder flex-align-center flex-justify-center">
                                        <a href="/checkout" className="btn blue-btn">
                                            <span>Reveal The Fake</span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="image-with-text-full-width content-first">
                        <div className="flex-wrap-setup">
                            <div className="image">
                                <img src={ imageWithText1 } alt="" className="img-as-block" />
                            </div>
                            <div className="content">
                                <div className="wrap">
                                    <h3 className="text-black text-uppercase">Even the most careful people are at risk</h3>
                                    <h2 className="big gradient-text"><span>Your Information Is Already Out There</span></h2>
                                    <p><strong className="text-black">And this just scratches the surface of what is known about you.</strong></p>
                                            
                                    <div className="btn-flex-holder">
                                        <a href="/checkout" className="btn blue-btn">
                                            <span>Have I Been Hacked</span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="flexbox section prevent-box">
                        <div className="wrapper text-center">
                            <div className="small-wrap">
                                <h3 className="text-uppercase">KNOW THE RISK</h3>
                                <h2 className="big gradient-text"><span>The Growing Threat of AI Voice Scams</span></h2>
                                <p>At the core of our mission is the belief that everyone deserves peace of mind when navigating the online realm. We strive to empower our clients with the tools and information they need to defend against cyber-attacks, data breaches, and identity theft. By offering a robust suite of Cyber Security services, we aim to make the internet a safer place for individuals.</p>
                            </div>
                            
                            <div className="flex-wrap flex-wrap-block col-gap-30 icon-grid custom-slick-slider-6">
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon1 } alt="" /></div>
                                        <p><span className="text-blue">1 in 4 people</span> have experienced or known someone who experienced on Al voice cloning scorn.</p>
                                    </div>
                                </div>
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon2 } alt="" /></div>
                                        <p><span className="text-blue">70% of people</span> don’t feel they could tell the difference between a real voice from on Al-generoted one.</p>
                                    </div>
                                </div>
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon3 } alt="" /></div>
                                        <p>Victims of Al voice cloning scams reported losing <span className="text-blue">up to $15,000</span> on average.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="flexbox section content-grid-block radial6-bg loaded">
                        <div className="wrapper">
                            <div className="flex-wrap col-gap-30 content-right">
                                <div className="one-half">
                                    <div className="content-block width-2">
                                        <h3 className="text-uppercase">YOUR BENEFITS</h3>
                                        <h2 className="big gradient-text"><span>CyberAlerts is Your Ultimate Defense</span></h2>
                                    </div>
                                </div>
                                <div className="one-half">
                                    <div className="offset-content-1">
                                    <p>At the core of our mission is the belief that everyone deserves peace of mind when navigating the online realm. We strive to empower our clients with the tools and information they need to defend against cyber-attacks, data breaches, and identity theft. By offering a robust suite of Cyber Security services, we aim to make the internet a safer place for individuals.</p>
                                    
                                    <div className="btn-flex-holder">
                                        <a href="/checkout" className="btn blue-btn">
                                            <span>Have I Been Hacked</span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                            </svg>
                                        </a>
                                    </div>
                                    </div>
                                </div>
                            </div>


                            <div className="grid-icon-boxes">
                                <div className="grid-icon-box">
                                    <div className="icon"><img loading="lazy" src={ gridIcon1 } alt="" className="img-as-block" /></div>
                                    <h3>Dark Web Monitoring</h3>
                                    <p>Keep your sensitive data safe by actively monitoring the dark web for any leaked information related to your organization.</p>
                                    <div className="learn-more">
                                        <a href="/service-page-template">Learn more 
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                            <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="grid-icon-box">
                                    <div className="icon"><img loading="lazy" src={ gridIcon2 } alt="" className="img-as-block" /></div>
                                    <h3>Identity Theft Protection</h3>
                                    <p>Get alerted to any suspicious activities related to your personal information, minimizing the risk of identity theft.</p>
                                    <div className="learn-more">
                                        <a href="/service-page-template">Learn more 
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                            <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="grid-icon-box">
                                    <div className="icon"><img loading="lazy" src={ gridIcon5 } alt="" className="img-as-block" /></div>
                                    <h3>Vulnerability Scanning and Assessment</h3>
                                    <p>Our platform provides regular vulnerability scans and assessments to identify and prioritize potential security risks, allowing our clients to take proactive measures to protect their systems.</p>
                                    <div className="learn-more">
                                        <a href="/service-page-template">Learn more 
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                            <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="grid-icon-box">
                                    <div className="icon"><img loading="lazy" src={ gridIcon6 } alt="" className="img-as-block" /></div>
                                    <h3>Critical CVE Alerts</h3>
                                    <p>Stay ahead of cyber threats with real-time alerts on critical Common Vulnerabilities and Exposures (CVEs).</p>
                                    <div className="learn-more">
                                        <a href="/service-page-template">Learn more 
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                            <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="section flexbox price-table radial6-bg lazyload-bg">
                        <div className="wrapper">
                            <div className="small-wrap text-center">
                                <h3 className="text-uppercase">Plans & Pricing</h3>
                                <h2 className="big gradient-text"><span>Start Protecting Your Identity Today</span></h2>
                                <h3>Enroll & Get Start Protecting Your Identity!</h3>
                            </div>
                            <div className="price-table-section">
                                <div className="price-table-block">
                                    <div className="price-table-title text-center">24 HOUR TRIAL</div>
                                    <div className="price-table-price text-center"><span className="price">$1.00</span><span className="month text-blue" style={{ display: 'none'}}>Then $39.99/mo</span></div>
                                    <div className="price-table-content text-center">
                                        <p className="med text-black">Round-the-clock availability, technical support & monitoring</p>
                                    </div>
                                    <div className="price-table-btn">
                                        <a href="/checkout?product=1" className="btn blue-btn">
                                            <span>Start 24 Hour Trial</span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                            <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                            </svg>
                                        </a>
                                    </div>
                                    
                                    <div className="price-table-list">
                                        <ul>
                                            <li>
                                                <div className="accord-item no-click">Dark web monitoring</div>
                                            </li>
                                            <li>
                                                <div className="accord-item no-click">Personal dashboard</div>
                                            </li>
                                            <li>
                                                <div className="accord-item no-click">Real-time alerts</div>
                                            </li>
                                            <li>
                                                <div className="accord-item no-click">Monitor emails, phone numbers & identities</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="view-all-pricing">
                                <a href="/pricing">View all features included in this plan</a>
                            </div>




                            <div className="secure-images">
                                <div className="first flex-wrap flex-justify-center flex-align-center col-gap-30">
                                    <img loading="lazy" src={ SecureImage4 } alt="" className="img-as-block" />
                                </div>
                                <div className=" flex-wrap flex-justify-center flex-align-center col-gap-30">
                                    <img loading="lazy" src={ SecureImage1 } alt="" className="img-as-block" />
                                    <img loading="lazy" src={ SecureImage2 } alt="" className="img-as-block" />
                                    <img loading="lazy" src={ SecureImage3 } alt="" className="img-as-block" />
                                </div>
                            </div>
                            <div className="safe-secure-content text-center">
                                <p>* The introductory purchase price today is valid for the first term of your new subscription. Your subscription renews each year at the then-current subscription renewal price. Pricing is subject to change.</p>
                            </div>
                        </div>
                    </section>


                    

                    <SliderCustomersV2 />



                    
                    <section className="section custom-banner-layout-1">
                        <div className="wrapper">
                            <img src={ CustomBanner} alt="" className="img-as-block mobile-only" />
                            <div className="small-wrapper">
                                <h2 className="big gradient-text"><span>Don’t Wait. Get Protected Today.</span></h2>
                                <div className="btn-flex-holder">
                                    <a href="/checkout" className="btn blue-btn">
                                        <span>Have I Been Hacked</span>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                            <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>

                    
                    <Footer />

                </div>
            </div>
        </>
    )
}

export default LandingPageV2R2