import logo from '../assets/images/logo.png'
import footerTrustedLogo from '../assets/images/footer-trust-logos.png'

import ButtonCheckout from './ButtonCheckout';
import PhoneNumber from '../globals/PhoneNumber';
import EmailLink from '../globals/EmailLink';

const Footer = () =>{
    return (
        <>
        <footer id="main-footer" className="">
            <div className="top-footer section">
                <div className="wrapper">
                    <div className="flex-wrap main-flex flex-justify-between">
                        <div className="footer-logo"><img loading="lazy" src={ logo } alt="" className="img-as-block" /></div>
                        <div className="footer-menus">
                            <div className="flex-wrap footer-menu-flex">
                                <div className="footer-menu">
                                    <h5>Cyber Security</h5>
                                    <ul>
                                        <li><a href="/dark-web-monitoring">Dark Web Monitoring</a></li>
                                        <li><a href="/identity-theft-protection">Identity Theft Protection</a></li>
                                        <li><a href="/critical-cve-alerts">Critical CVE Alerts</a></li>
                                        <li><a href="/vulnerability-scanning-and-assessment">Vulnerability scanning and assessment</a></li>
                                    </ul>
                                </div>
                                <div className="footer-menu">
                                    <h5>Product</h5>
                                    <ul>
                                        <li><a href="/about">Features</a></li>
                                        <li><a href="/pricing">Pricing</a></li>
                                        <li><a href="/login">Login</a></li>
                                        <li><a href="/faq">FAQ</a></li>
                                        <li><a href="/checkout">Signup</a></li>
                                    </ul>
                                </div>
                                <div className="footer-menu">
                                    <h5>Company</h5>
                                    <ul>
                                        <li><a href="/about">About Us</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                        <li><a href="/blog">Articles</a></li>
                                        <li><a href="/contact">Careers</a></li>
                                    </ul>
                                </div>
                                <div className="footer-menu">
                                    <h5>Legal</h5>
                                    <ul>
                                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                                        <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                                        <li><a href="/blog">Articles</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-contacts">
                            <div><EmailLink /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section bottom-footer">
                <div className="wrapper">
                    <div className="btn-flex-holder">
                        <ButtonCheckout classes="btn black-btn" />
                    </div>
                    
                    <div className="footer-trust-logos">
                        <img loading="lazy" src={ footerTrustedLogo } alt="" className="img-as-block" />
                    </div>
                    
                    <div className="copyright">&copy; {(new Date().getFullYear())}  Copyright CyberAlerts Inc.  |  All Rights Reserved</div>
                    
                </div>
            </div>
        </footer>
        </>
    );
}
export default Footer